import React from 'react'
import ReactDOM from 'react-dom'

import AvatarLayout from 'avatarLayout'
import ExperienceLayout from 'experienceLayout'

import './index.css'

ReactDOM.render(
  <React.StrictMode>
    {/* <AvatarLayout /> */}
    <ExperienceLayout />
  </React.StrictMode>,
  document.getElementById('root')
)
